
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
//@import "variables";

// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";



// Bulma
// 1. Import initial Bulma variables
@import "node_modules/bulma/sass/utilities/initial-variables";

// 2. Create own variables

//$info: #81d4fa;
//$success: #4caf50;
//$warning: #ff9800;
//$danger: #f44336;
//
//$fedrus-red: #a10d2e;
//$fedrus-red-hover: #a02844;
//$fedrus-gray: #303b3d;
//$fedrus-light-gray: #43585a;
//$light-blue: #c1e8f2;

// 3. Customise derived variables
//$primary: $fedrus-red;
//$primary-hover: $fedrus-red-hover;
@import "colours";

// 4. Import de rest of Bulma
@import "node_modules/bulma/bulma";

@import "fedrus";
@import "transitions";
@import "fab";
@import "inspections";