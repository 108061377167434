// 2. Create own variables
$fedrus-red: #311d67;
$fedrus-red-hover: #3f2584;
$fedrus-gray: #303b3d;
$fedrus-light-gray: #43585a;
$light-blue: #c1e8f2;
$white: #ffffff;

// 3. Customise derived variables
$primary: $fedrus-red;
$primary-hover: $fedrus-red-hover;
$info: #81d4fa;
$success: #4caf50;
$warning: #ffc107;
$danger: #f44336;

$primary-light: #dba2af;
$info-light: #b3e5fc;
$success-light: #c8e6c9;
$warning-light: #ffecb3;
$danger-light: #ffcdd2;

$inactive-foreground: #7a7a7a;

$pagination-current-background-color: $primary;
$pagination-current-border-color: $primary;
$pagination-current-color: $white;
$pagination-color: $white;
$pagination-border-color: $white;
$pagination-hover-color: $white;
$pagination-hover-border-color: $primary;