[v-cloak]
  display: none

.card-header.is-primary, .navbar.is-primary
  background-color: $primary
  color: $primary-invert

.card-header.is-primary .card-header-title, .card-header.is-primary .title
  color: $primary-invert

nav .navbar-brand
  background-color: $primary
  color: $primary-invert

.navbar.is-primary .navbar-burger span
  background-color: $primary-invert

.navbar.is-primary .navbar-burger:hover
  background-color: $primary-hover

.navbar-menu
  background-color: white

.navbar-menu .menu-label
  margin-bottom: 0em

.navbar .navbar-brand .title
  font-size: 1.7remn

.menu .menu-label
  margin-top: 1em

.menu-list a.active
  background-color: whitesmoke
  color: $primary

.is-shaded
  background-color: $white-ter

aside.has-shadow
  box-shadow: 2px 0 3px rgba(10, 10, 10, 0.1)
  z-index: 10

.has-padding
  padding: 1.5em

.has-medium-padding
  padding: 1em

.has-small-padding
  padding: 0.5em

.columns.is-gapless > .column.has-padding
  padding: 1.5em !important

.discrete
  opacity: 0.1

.weight-semibold
  font-weight: $weight-semibold

.weight-almostbold
  font-weight: 600

.weight-bold
  font-weight: $weight-bold

hr.dense
  margin: 0.5rem 0

hr.medium
  margin: 1rem 0

#app
  margin-bottom: 0

#main-content-container
  display: flex
  flex-flow: column
  height: 100%

.is-fg-white
  color: $white

.only-row-hover
  +desktop
    opacity: 0

// ObjectTable

tr:hover .only-row-hover
  +desktop
    opacity: 1
    transition: all .3s ease

table.table thead tr
  background-color: $fedrus-gray
  &:hover
    background-color: $fedrus-gray
    td
      background-color: $fedrus-gray

table.table thead th
  color: $white
  white-space: nowrap
  &.sortable
    cursor: pointer

.fedrus-modal
  .modal-card-head
    background-color: $white
    border-width: 0px
  .modal-card-body
    padding: 0 20px
  .modal-card-foot
    display: block
    background-color: $white
    border-width: 0px

tr.is-disabled
  background-color: $white
  td
    background-color: $white
  &:hover
    background-color: $white
    cursor: default
    td
      cursor: default

tr.is-deleting
  color: $black
  background-color: $red
  opacity: 0.3
  td
    color: $black
    background-color: $red
  &:hover
    color: $black
    background-color: $red
    cursor: default
    td
      cursor: default

tr.is-restoring
  color: $black
  background-color: $green
  opacity: 0.3
  td
    color: $black
    background-color: $green
  &:hover
    color: $black
    background-color: $green
    cursor: default
    td
      cursor: default

tr.is-highlight, .table.is-striped tbody tr.is-highlight:nth-child(2n)
  background-color: $light-blue
  td
    background-color: $light-blue
  &:hover
    background-color: $light-blue

tr.is-warning, .table.is-striped tbody tr.is-warning:nth-child(2n)
  background-color: $warning-light
  &:hover
    background-color: $warning

// ObjectTable search
.object-search
  padding-bottom: 0.5rem
  .is-mobile
    padding-top: 0.5rem
  .type
    max-width: 273px

#app section.section
  padding: 0
  background-color: #0090b2
  min-height: 100%
  +tablet
    padding: 1.5rem
  &.padding
    padding: 1.5rem

.table th.has-text-right
  text-align: right

a.link-white
  color: $white
  &:link, &:active, &:visited, &:hover
    color: $white
  &:hover
    text-decoration: underline

.text-smaller
  font-size: 0.75rem

label[data-required]::after
  color: $red
  content: '*'

.margin-top
  margin-top: 1.5rem

.margin-top-small
  margin-top: 0.5rem

.margin-right-small
  margin-right: 0.5rem

.editor-placeholder
  background-color: $white-ter
  padding: 1.5rem
  vertical-align: middle
  line-height: 36px

.control.wrap
  flex-basis: 0
  min-width: 100px

.notification
  padding-right: 1.5rem

.notification.is-outlined
  border: 1px solid whitesmoke
  color: $light-invert
  &.is-primary
    border-color: $primary
  &.is-info
    border-color: $info
  &.is-success
    border-color: $success
  &.is-warning
    border-color: $warning
  &.is-danger
    border-color: $danger

.notification.is-outlined:not(.is-light)
  background-color: #ffffff
  &.is-primary
    color: $primary
  &.is-info
    color: $info
  &.is-success
    color: $success
  &.is-warning
    color: $warning
  &.is-danger
    color: $danger
  .no-color
    color: $light-invert

.notification.is-light
  color: $light-invert
  &.is-primary
    background-color: $primary-light
  &.is-info
    background-color: $info-light
  &.is-success
    background-color: $success-light
  &.is-warning
    background-color: $warning-light
  &.is-danger
    background-color: $danger-light

.card-content.small-padding, .notification.small-padding
  padding: 0.5rem

.card-content.medium-padding, .notification.medium-padding
  padding: 0.75rem

.card-content.no-padding, .notification.no-padding
  padding: 0

.columns.mobile-margin
  +mobile
    margin: 0.25rem

.card
  border-radius: 2px

.card-header-title.is-primary
  background-color: $primary
  color: $primary-invert

.card.is-danger
  background-color: $danger
  color: $danger-invert

.card.is-info
  background-color: $info-light

.card.is-warning
  background-color: $warning-light

.list-item
  padding: 0.5rem
  cursor: pointer
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  justify-content: space-between
  align-items: stretch
  &.has-separator:not(:last-of-type)
    border-bottom: 1px solid whitesmoke
  .list-item-before
    flex: 0 1 auto
    margin-right: 0.5rem
  .list-item-after
    flex: 0 1 auto
    margin-left: 0.5rem
  .list-item-content
    flex: 1 1 auto

.list-item:hover
  background-color: whitesmoke
  transition-property: background-color
  transition-duration: 0.3s
  transition-timing-function: ease

.italic
  font-style: italic

.margin-bottom
  margin-bottom: 1rem

.level-vertical
  flex-direction: column
  align-items: stretch
  justify-content: flex-end
  align-self: stretch
  a.button
    width: 100%

.level.is-mobile .level-vertical > .level-item:not(:last-of-type)
  margin-bottom: 0.5rem
  margin-right: 0

.level.is-mobile .level-vertical .level-item
  flex-grow: 0

.level.no-margin-bottom
  margin-bottom: 0

.level:not(:last-child).small-margin-bottom
  margin-bottom: 0.5rem

.content ul
  margin-top: 0.5rem
  ul
    margin-top: 0

ul.disc
  list-style-type: disc

ul.circle
  list-style-type: circle

span
  &.is-success
    color: $success
  &.is-danger
    color: $danger
  &.is-warning
    color: $warning
  &.is-info
    color: $info
  &.is-primary
    color: $primary

.textarea-addon a.button
  border-top-left-radius: 0
  border-top-right-radius: 0
  margin-top: -1px

.no-margin, .level.no-margin
  margin: 0

.hidden, .level.hidden
  display: none
